import type { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import type { Route } from '../../config/routes.config';
import type { DropDownProps } from './DropDown.types';
import {
  Menu,
  MenuTrigger,
  MenuList,
  MenuItem,
  MenuPopover,
  Text,
} from '@fluentui/react-components';
import { mergeClasses } from '@griffel/react';
import { useDropDownStyles } from './DropDown.styles';

const DropDown: FunctionComponent<DropDownProps> = (props: DropDownProps) => {
  const classes = useDropDownStyles();

  if (props.route.hide) {
    return <></>;
  } else {
    if (props.route.children && props.route.children.length > 0) {
      const dropdownItems = props.route.children.map((childRoute: Route) => {
        let menuItem = <DropDown route={childRoute} isSubmenu={true} />;

        if (childRoute.children && childRoute.children.length > 0) {
          if (props.route.noLink) {
            menuItem = (
              <Text as={'p'} key={childRoute.id} className={classes.noLink}>
                {childRoute.name}
              </Text>
            );
          } else {
            menuItem = (
              <NavLink
                end
                to={childRoute.path}
                key={childRoute.id}
                className={({ isActive }) =>
                  mergeClasses(classes.link, isActive ? classes.isActive : '')
                }>
                <span>{childRoute.name}</span>
              </NavLink>
            );
          }
        }

        return (
          <MenuItem key={childRoute.id} className={classes.item}>
            {menuItem}
          </MenuItem>
        );
      });

      return (
        <Menu openOnHover>
          <MenuTrigger>
            <MenuItem
              className={mergeClasses(
                classes.item,
                props.isSubmenu ? classes.innerItem : ''
              )}>
              {props.route.name}
            </MenuItem>
          </MenuTrigger>

          <MenuPopover className={classes.menu}>
            <MenuList className={classes.menuList}>{dropdownItems}</MenuList>
          </MenuPopover>
        </Menu>
      );
    } else {
      if (props.route.noLink) {
        return (
          <Text as={'p'} key={props.route.id} className={classes.noLink}>
            {props.route.name}
          </Text>
        );
      } else {
        return (
          <NavLink
            end
            to={props.route.path}
            key={props.route.id}
            className={({ isActive }) =>
              mergeClasses(classes.item, classes.link, isActive ? classes.isActive : '')
            }>
            <span>{props.route.name}</span>
          </NavLink>
        );
      }
    }
  }
};

export { DropDown };
