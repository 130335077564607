import type { FunctionComponent } from 'react';
import type { ErrorProps } from './Error.types';
import { useErrorStyles } from './Error.styles';
import { MetaInfo } from '../../components';

const NOT_FOUND_TITLE = '404: Not Found';
const NOT_FOUND_DESCRIPTION = 'The requested page could not be found.';

const Error: FunctionComponent<ErrorProps> = (props: ErrorProps) => {
  const classes = useErrorStyles();

  return (
    <main className={classes.root}>
      <MetaInfo title={props.title} description={props.description} />
      <section className={classes.card}>
        <h1 className={classes.title}>{props.title}</h1>
        <p className={classes.message}>{props.description}</p>
      </section>
    </main>
  );
};

const NotFound404Error: FunctionComponent<Partial<ErrorProps>> = (
  props: Partial<ErrorProps>
) => {
  return (
    <Error
      title={props.title ?? NOT_FOUND_TITLE}
      description={props.description ?? NOT_FOUND_DESCRIPTION}
    />
  );
};

export { Error, NotFound404Error };
